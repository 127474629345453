<template>
    <div class="rounded-3xl border-2 border-[#E8E8E8] flex items-center p-2 gap-4 max-h-16 bg-white">
        <div class="rounded-full border-2 border-[#E8E8E8] min-w-10 min-h-10 flex items-center justify-center body-2 uppercase text-[#555BA2]">{{ fileType }}</div>
        <div class="grow mr-2">
            <div class="body-2 mb-1 w-max max-w-48">{{ fileType === "txt" ? fileDescription : fileName }}</div>
            <span v-if="fileSize" class="rounded-full border-2 border-[#B8C9FF] bg-[#F0F5FF] body-2 text-[#555BA2] px-3 py-0.5 whitespace-nowrap">{{ fileSize }}</span>
        </div>
        <button v-if="isRemovable" type="button" class="border-2 border-[#E8E8E8] rounded-full flex items-center justify-center min-w-6 min-h-6" @click="$emit('remove')">
            <i class="bi bi-x icon-bold text-[#8C8C8C] leading-none" />
        </button>
    </div>
</template>

<script setup>
import { computed } from "vue";
const { file } = defineProps({
    file: { type: Object, required: true },
    isRemovable: { type: Boolean, default: false },
});
defineEmits(["remove"]);
const fileType = computed(() => {
    const type = file.type;
    if (!type) {
        return "txt";
    }
    return type;
});

const fileName = computed(() => {
    const MAX_FILE_NAME_LENGTH = 30;
    const name = file.name;
    if (name.length >= MAX_FILE_NAME_LENGTH) {
        return `${name.slice(0, MAX_FILE_NAME_LENGTH / 2)}...${name.slice(name.length - MAX_FILE_NAME_LENGTH / 2, name.length)}`;
    }
    return name;
});

const fileDescription = computed(() => {
    const MAX_DESC_LENGTH = 100;
    const description = file.description;
    if (description.length >= MAX_DESC_LENGTH) {
        return `${description}...`;
    }
    return description;
});

const fileSize = computed(() => {
    const size = file.size;
    if (!size) {
        return undefined;
    }
    if (size < 1024) {
        return `${size} B`;
    } else if (size < 1024 * 1024) {
        return `${Math.floor(size / 1024)} KB`;
    } else {
        return `${Math.floor(size / 1024 / 1024)} MB`;
    }
});
</script>
